<template>
  <div class="help-video-list-item" @click.prevent="$emit('selected', helpVideo)">
    <div class="left" :style="leftStyle"></div>
    <div class="right">
      <div class="name">
        {{ helpVideo["$v"]["displayName"] }}
      </div>
      <!-- <div>
        {{ helpVideo["$v"]["contextQuote"] }}
      </div> -->
    </div>
  </div>
</template>

<script>
import helpVideos from "../../../../api/guidance/help-videos";
export default {
  props: ["helpVideo", "tenantId", "orgId"],
  data() {
    return {
      isLoadingThumbnailUrl: false,
      thumbnailUrl: null,
    };
  },
  computed: {
    leftStyle() {
      if (this.thumbnailUrl) {
        return {
          backgroundImage: "url(" + this.thumbnailUrl.url + ")",
        };
      }
      return {
        backgroundColor: "rgba(81, 85, 234, 0.1)",
      };
    },
  },
  created() {
    this.getThumbnailUrl();
  },
  methods: {
    getThumbnailUrl() {
      let vm = this;
      vm.isLoadingThumbnailUrl = true;
      helpVideos
        .getHelpVideoThumbProtectedUrl(this.tenantId, this.helpVideo["$v"]["id"])
        .then((r) => {
          vm.isLoadingThumbnailUrl = false;
          vm.thumbnailUrl = r.data;
        })
        .catch((e) => {
          console.log(e);
          // vm.$message.error("Error loading image");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.help-video-list-item {
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;
  overflow: hidden;
  min-height: 75px;

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  display: flex;
  .left {
    flex-shrink: 1;
    min-width: 100px;
    width: 100px;
    max-width: 100px;
    background-size: cover;
    background-position: center;
  }
  .right {
    min-height: 75px;
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-items: center;
    height: 100%;
    justify-content: center;
    .name {
      // margin-bottom: 5px;
      font-weight: 500;
    }
    position: relative;
  }
}
</style>